import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tourEndpoints } from 'services/tourEndpoints';
import { driverEndpoints } from 'services/driverEndpoints';
import { waypointEndpoints } from 'services/waypointEndpoints';
import { deliveryEndpoints } from 'services/deliveryEndpoints';
import { carrierEndpoints } from 'services/carrierEndpoints';
import { DestinationJson } from 'app/models/Destination';
import { ColorJSON } from 'app/models/Color';
import { partOrderEndpoints } from 'services/partOrderEndpoints';
import baseQueryWithErrorHandling from 'services/kewloxBaseQuery';

interface PickupQueryInfos {
  orderId: string;
  pickupCode: string;
}

interface PickupQueryInfosResponse {
  orderId: string;
  status: number;
}

export const kewloxApi = createApi({
  reducerPath: 'kewloxApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    'Waypoint',
    'Delivery',
    'Driver',
    'CarrierConfiguration',
    'Tour',
    'PartOrder',
    'Carrier',
    'Destination',
    'Color',
  ],
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/auth/token/',
        method: 'POST',
        body: credentials,
      }),
    }),
    getOrderPickupInfo: builder.query<
      PickupQueryInfosResponse,
      PickupQueryInfos
    >({
      query: ({ orderId, pickupCode }) => ({
        url: `order/${orderId}/pickup_infos/?pc=${pickupCode}`,
        method: `GET`,
      }),
    }),
    getDestinations: builder.query<DestinationJson[], void>({
      query: () => `/destination/`,
      providesTags: ['Destination'],
    }),
    getColors: builder.query<ColorJSON[], void>({
      query: () => `/color/`,
      providesTags: ['Color'],
    }),
    ...carrierEndpoints(builder),
    ...waypointEndpoints(builder),
    ...driverEndpoints(builder),
    ...tourEndpoints(builder),
    ...deliveryEndpoints(builder),
    ...partOrderEndpoints(builder),
  }),
});

export const {
  useLoginMutation,
  useGetAllWaypointsQuery,
  useGetWaypointQuery,
  useAddWaypointMutation,
  useUpdateWaypointMutation,
  useDeleteWaypointMutation,
  useGetOrderPickupInfoQuery,
  useGetCarrierConfigurationQuery,
  useUpdateCarrierConfigurationMutation,
  useGetAllDriversQuery,
  useGetDriverQuery,
  useAddDriverMutation,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
  useGetTourHistoryQuery,
  useNotifyAllCustomersMutation,
  useNotifyDeliveryCustomerMutation,
  useGetActiveToursQuery,
  useGetTourQuery,
  useAddDeliveryMutation,
  useGetDeliveryQuery,
  useUpdateDeliveryMutation,
  useDeleteDeliveryMutation,
  useConfirmDeliveryMutation,
  useCarrierReservationMutation,
  useActionDeliveryMutation,
  useGetCarriersQuery,
  useGetDestinationsQuery,
  useGetColorsQuery,
  useGetPartOrderQuery,
  useGetPartOrderByIdQuery,
  useUpdatePartOrderMutation,
  useSetDriversTourMutation,
  useResetPartOrderMutation,
  useSubdividePartOrderMutation,
  useMergePackagesMutation,
  useSplitPackagesMutation,
  useAddPackagesMutation,
  useUpdateTourMutation,
  useGetDeliveriesForToursQuery,
} = kewloxApi;
