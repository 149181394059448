import {
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { API_BASE_URL } from 'app/constants';

const kewloxBaseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('authorization', `JWT ${token}`);
    }
    return headers;
  },
});

const baseQueryWithErrorHandling = async (
  args: any,
  api: any,
  extraOptions: any,
) => {
  const result = await kewloxBaseQuery(args, api, extraOptions);

  if (result.error) {
    let message = "Une erreur inattendue s'est produite";
    // console.log('Error from baseQueryWithErrorHandling', result.error);

    if (result.error.status === 'FETCH_ERROR') {
      message = 'Erreur de connexion au serveur';
      toast.error(`${message}`);
    }

    // if (result.error.error){
    //   message = result.error.error;
    // } else if (result.error.statusText)

    // const message =
    //   result.error.data?.detail ||
    //   result.error.status.toString() ||
    //   "Une erreur inattendue s'est produite";
  }

  return result;
};

export default baseQueryWithErrorHandling;
