import { PartOrder } from 'app/models/PartOrder';
import { Tour } from 'app/models/Tour';

export interface DeliveryJson {
  id: number;
  order?: any; //number;
  partOrder?: number;
  tour: Tour | number;
  position: number;
  manual: boolean;
  notification: boolean;
  confirmed: boolean;
  duration?: number;
  deliveryTime?: string;
  customerTime?: string;
  unloadingTime: number;
  waypoint?: number;
  waypointNote?: string;
  carrierOrderId?: string;
  carrierStatus?: string;
  carrierError?: boolean;
}

export interface Delivery extends DeliveryJson {
  order?: PartOrder;
  assembly: boolean;
}

export const createDelivery = (
  data: DeliveryJson,
  partOrder?: PartOrder,
): Delivery => {
  return {
    ...data,
    order: partOrder,
    assembly: !!partOrder?.orderInfo.assembly,
  };
};

// export class Delivery {
//   confirmed: boolean;
//   customerTime?: string;
//   deliveryTime?: string;
//   duration?: number;
//   id: number;
//   manual: boolean;
//   notification: boolean;
//   order?: PartOrder;
//   position: number;
//   tour: Tour | number;
//   unloadingTime: number;
//   waypoint?: number;
//   waypointNote?: string;
//
//   constructor(data: DeliveryJson, partOrder?: PartOrder) {
//     this.confirmed = data.confirmed;
//     this.id = data.id;
//     this.manual = data.manual;
//     this.notification = data.notification;
//     this.position = data.position;
//     this.tour = data.tour;
//     this.unloadingTime = data.unloadingTime;
//     if (data.customerTime) this.customerTime = data.customerTime;
//     if (data.deliveryTime) this.deliveryTime = data.deliveryTime;
//     if (data.duration) this.duration = data.duration;
//     if (partOrder) this.order = partOrder;
//     if (data.waypoint) this.waypoint = data.waypoint;
//     if (data.waypointNote) this.waypointNote = data.waypointNote;
//   }
//
//   get assembly(): boolean {
//     return !!this.order?.orderInfo.assembly;
//   }
// }
