import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AppRootState } from 'index';
import { kewloxApi } from 'services/kewloxApi';
import { createDelivery, Delivery, DeliveryJson } from 'app/models/Delivery';
import { TourJson } from 'app/models/Tour';
import { selectPartOrderById } from 'store/slices/partOrderSlice';

export const deliveryAdapter = createEntityAdapter<Delivery>({
  selectId: delivery => delivery.id,
});

const deliverySlice = createSlice({
  name: 'deliverySlice',
  initialState: deliveryAdapter.getInitialState({
    loadingCarrierIds: [] as number[],
  }),
  reducers: {
    addDeliveries: deliveryAdapter.addMany,
    updateDelivery: deliveryAdapter.updateOne,
  },
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getActiveTours.matchFulfilled,
      (state, { payload }) => {
        const deliveriesData = payload.map((item: TourJson) => {
          const deliveriesForTour = item.orders.map((order: DeliveryJson) =>
            createDelivery(order),
          );
          return deliveriesForTour;
        });
        // Flat the deliveriesData array
        const transformedPayload = [].concat(...deliveriesData);
        deliveryAdapter.setAll(state, transformedPayload);
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.getDelivery.matchFulfilled,
      (state, { payload }) => {
        deliveryAdapter.setOne(state, createDelivery(payload));
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.addDelivery.matchFulfilled,
      (state, { payload }) => {
        deliveryAdapter.addOne(state, createDelivery(payload));
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.updateDelivery.matchFulfilled,
      (state, { payload }) => {
        deliveryAdapter.updateOne(state, { id: payload.id, changes: payload });
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.deleteDelivery.matchFulfilled,
      (state, { meta }) => {
        deliveryAdapter.removeOne(state, meta.arg.originalArgs as number);
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.confirmDelivery.matchFulfilled,
      (state, { meta, payload }) => {
        const deliveryId = meta.arg.originalArgs;
        deliveryAdapter.updateOne(state, {
          id: deliveryId,
          changes: { confirmed: payload.confirmed, carrierError: false },
        });
      },
    );
    // Carrier reservation
    builder.addMatcher(
      kewloxApi.endpoints.carrierReservation.matchFulfilled,
      (state, { payload }) => {
        deliveryAdapter.updateOne(state, {
          id: payload.id,
          changes: { confirmed: payload.confirmed, carrierError: false },
        });
        state.loadingCarrierIds = state.loadingCarrierIds.filter(
          id => id !== payload.id,
        );
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.carrierReservation.matchRejected,
      (state, { meta }) => {
        const deliveryId = meta.arg.originalArgs;
        deliveryAdapter.updateOne(state, {
          id: deliveryId,
          changes: { carrierError: true },
        });
        state.loadingCarrierIds = state.loadingCarrierIds.filter(
          id => id !== deliveryId,
        );
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.carrierReservation.matchPending,
      (state, { meta }) => {
        const deliveryId = meta.arg.originalArgs;

        if (!state.loadingCarrierIds.includes(deliveryId)) {
          state.loadingCarrierIds.push(deliveryId);
        }

        deliveryAdapter.updateOne(state, {
          id: deliveryId,
          changes: { carrierError: false },
        });
      },
    );

    builder.addMatcher(
      kewloxApi.endpoints.getDeliveriesForTours.matchFulfilled,
      (state, { payload }) => {
        deliveryAdapter.addMany(
          state,
          payload.deliveries.map(delivery => createDelivery(delivery)),
        );
      },
    );
  },
});

export const { addDeliveries, updateDelivery } = deliverySlice.actions;

export const {
  selectById: selectDeliveryById,
  selectIds: selectDeliveryIds,
  selectEntities: selectDeliveryEntities,
  selectAll: selectAllDeliveries,
} = deliveryAdapter.getSelectors((state: AppRootState) => state.delivery);

export const selectPartOrderInDelivery = () =>
  createSelector([selectAllDeliveries], (deliveries): number[] => {
    return deliveries.reduce<number[]>((acc, delivery) => {
      if (delivery.partOrder !== undefined) {
        acc.push(delivery.partOrder);
      }
      return acc;
    }, []);
  });

export const selectDeliveryFromPartOrderId = (partOrderId: number) =>
  createSelector(
    [
      (state: AppRootState) => selectAllDeliveries(state),
      (state: AppRootState) => selectPartOrderById(state, partOrderId),
    ],
    (deliveries, partOrder) => {
      if (!partOrder) {
        return undefined;
      }
      return deliveries.find(delivery => delivery.partOrder === partOrder.id);
    },
  );

export const selectLoadingCarrierIds = () =>
  createSelector(
    (state: AppRootState) => state.delivery,
    delivery => delivery.loadingCarrierIds,
  );

export default deliverySlice.reducer;
