import React, { useEffect } from 'react';
import ToursList from 'app/containers/ToursList';
import OrderList from 'app/containers/OrderList';
import { DragDropContext, DragStart } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDelivery,
  deleteDeliveryAction,
  selectDestinationFilter,
  setOrderTourDragging,
  updateDelivery,
} from 'app/containers/ToursList/slice';
import { TourMap } from 'app/containers/TourMap';
import { selectOrderAction } from 'app/containers/TourMap/slice';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { selectedTour } from 'app/containers/ToursList/selectors';
import SiteWrapper from 'app/containers/SiteWrapper';
import OrderDetails from 'app/containers/OrderDetails';
import AddTour from 'app/containers/ToursList/AddTour';
import DestinationTableHeader from 'app/containers/OrderList/DestinationTableHeader';
import { SearchField } from 'app/containers/SearchField';
import CuttingButton from 'app/containers/Cutting/CuttingButton';
import TourInfos from 'app/containers/TourInfos';
import {
  useGetActiveToursQuery,
  useGetAllWaypointsQuery,
  useGetPartOrderQuery,
} from 'services/kewloxApi';
import { getNumberOfCuttings } from 'app/containers/SiteWrapper/slice';
import PartOrdersPlaceholder from 'app/components/Placeholders/PartOrdersPlaceholder';
import {
  selectCurrentPartOrder,
  selectCurrentTour,
} from 'store/slices/activeContentSlice';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { selectAllDeliveries } from 'store/slices/deliverySlice';
import { selectTourEntities } from 'store/slices/toursSlice';
import { selectIsSearching } from 'app/containers/SearchField/selectors';
import { CircleSpinner } from 'react-spinners-kit';
import PollingAgent from 'app/containers/PollingAgent';
import { toast } from 'react-toastify';

export const ToursPage = () => {
  const dispatch = useDispatch();

  useGetAllWaypointsQuery();
  const {
    data: dataTours,
    error: errorTours,
    isLoading: isLoadingTours,
  } = useGetActiveToursQuery();

  const isSearching = useSelector(selectIsSearching);

  const partOrdersEntities = useSelector(selectPartOrderEntities);
  const deliveries = useSelector(selectAllDeliveries);
  const tour = useSelector(selectCurrentTour);

  const partOrder = useSelector(selectCurrentPartOrder());
  //const orderDetailsSelected = useSelector(selectOrderSelected);
  const tours = useSelector(selectTourEntities);

  const { isLoading: isLoadingPartOrders } = useGetPartOrderQuery();

  useEffect(() => {
    dispatch(getNumberOfCuttings());
  }, []);

  useEffect(() => {
    if (errorTours) {
      toast.error('Erreur lors du chargement des tournées');
    }
  }, [errorTours]);

  const displayPlaceholder = isLoadingTours || isLoadingPartOrders;

  const onBeforeCapture = d => {
    dispatch(setOrderTourDragging(true));
    const entry = partOrdersEntities[parseInt(d.draggableId)];
    if (entry) {
      dispatch(
        selectDestinationFilter({
          destination: entry.destination,
          list: false,
        }),
      );
    }
  };

  const onDragStart = (start: DragStart) => {
    const po = partOrdersEntities[parseInt(start.draggableId)];
    if (po) {
      dispatch(selectOrderAction(po));
    }
  };

  const onDragEnd = (result: any) => {
    dispatch(setOrderTourDragging(false));

    let partOrderId = result.draggableId;
    if (result.source.droppableId === 'order-details') {
      partOrderId = partOrderId.replace('drag-', '');
    }
    const dragPartOrder = partOrdersEntities[parseInt(partOrderId)];

    const delivery = deliveries.find(
      e => e.partOrder === parseInt(partOrderId),
    );
    let droppableTour;
    if (result.destination) {
      droppableTour = tours[parseInt(result.destination.droppableId)];
    }

    if (!result.destination || !result.destination.droppableId) return;
    if (result.destination.droppableId === 'order-details') return;

    if (result.destination.droppableId.includes('order-tables')) {
      if (delivery) {
        dispatch(deleteDeliveryAction(delivery));
      }
      return;
    }

    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index !== result.destination.index &&
      delivery
    ) {
      // Update the position
      if (droppableTour) {
        dispatch(
          updateDelivery(delivery, {
            position: result.destination.index,
            tour: droppableTour.id,
          }),
        );
        // dispatch(
        //   updateDeliveryPosition(
        //     delivery,
        //     droppableTour,
        //     result.destination.index,
        //   ),
        // );
      }
    } else if (
      (result.source.droppableId.includes('order-tables') ||
        result.source.droppableId === 'order-details') &&
      !result.destination.droppableId.includes('order-tables') &&
      result.destination.droppableId !== 'order-details'
    ) {
      if (droppableTour)
        dispatch(
          addDelivery(droppableTour, result.destination.index, dragPartOrder),
        );
    } else {
      if (delivery && droppableTour) {
        dispatch(updateDelivery(delivery, { tour: droppableTour.id }));
      }
    }
  };

  const showMap = import.meta.env.VITE_DISABLE_MAP !== 'true';

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeCapture={onBeforeCapture}
      onDragStart={onDragStart}
    >
      <SiteWrapper
        title="Planification des commandes"
        rightTopBarButton={
          <div className="flex">
            <CuttingButton />
            <SearchField />
            <div className="ml-4">
              <AddTour />
            </div>
          </div>
        }
      >
        <div className="flex flex-row">
          <div className="flex flex-col w-full">
            <PollingAgent />

            {isSearching && (
              <div className="absolute top-0 mt-16 z-100 w-full">
                <div className="py-8 text-xl flex justify-center items-center bg-gray-300">
                  Recherche en cours ...
                  <div className="mx-4">
                    <CircleSpinner size={30} color="#4a5568" />
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-shrink flex-grow min-h-0 h-full">
              <div className="hidden md:flex w-1/2">
                <div className="w-full">
                  <ToursList isLoading={displayPlaceholder} />
                </div>
              </div>
              <StickyContainer
                toursSize={Object.keys(tours).length}
                className="w-full md:w-1/2"
              >
                <StickyContent className="flex-shrink flex-grow min-h-0 h-full">
                  {partOrder ? (
                    <OrderDetails />
                  ) : (
                    <>
                      <div className="hidden md:block">
                        {showMap && <TourMap />}
                        {!tour?.direct && <TourInfos tour={tour} />}
                      </div>
                      <div className="flex w-full h-16 justify-center">
                        <DestinationTableHeader />
                      </div>
                      <StyledTable className="w-full py-0">
                        {displayPlaceholder ? (
                          <PartOrdersPlaceholder />
                        ) : (
                          <OrderList />
                        )}
                      </StyledTable>
                    </>
                  )}
                </StickyContent>
              </StickyContainer>
            </div>
          </div>
        </div>
      </SiteWrapper>
    </DragDropContext>
  );
};

const StyledTable = styled.div`
  overflow: auto;
`;

const StickyContainer = styled.div<{ toursSize: number }>`
  //background-color: #0000ff;
  //height: calc(${p => p.toursSize} * 160px);
`;

const StickyContent = styled.div`
  position: sticky;
  top: 4rem;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
`;
