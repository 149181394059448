import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DeliveryItem from 'app/containers/ToursList/Deliverytem';
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTour, updateTour } from 'app/containers/ToursList/slice';
import {
  FiFile,
  FiFlag,
  FiMoreVertical,
  FiSmartphone,
  FiTrash2,
} from 'react-icons/fi';
import { IoMdHand } from 'react-icons/io';
import { GoPackage } from 'react-icons/go';
import { format, parseISO, setHours, setMinutes } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { selectSearchFieldValue } from 'app/containers/SearchField/selectors';
import TourMapLink from 'app/containers/ToursList/TourItem/TourMapLink';
import { Tour } from 'app/models/Tour';
import { selectIsTourLoading } from 'app/containers/ToursList/selectors';
import { CircleSpinner } from 'react-spinners-kit';
import { Delivery } from 'app/models/Delivery';
import { FaUserAlt } from 'react-icons/fa';
import { ExternalCarrierOrderPages } from 'app/containers/TourPrinter/ExternalCarrierOrderPages';
import { ShopPackagesSheets } from 'app/containers/TourPrinter/ShopPackagesSheets';
import TourPrinterButton from 'app/containers/TourPrinter/TourPrinterButton';
import DriverSelector from 'app/pages/DriverPage/DriverSelector';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ButtonDatePicker from 'app/components/ButtonDatePicker';
import { fr } from 'date-fns/locale';
import { useNotifyAllCustomersMutation } from 'services/kewloxApi';
import { selectDeliveriesFromTour } from 'store/slices/toursSlice';
import { TourNote } from 'app/containers/ToursList/TourItem/TourNote';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { setCurrentTourId } from 'store/slices/activeContentSlice';
import WaypointModal from 'app/containers/WaypointModal';

interface Props {
  tour: Tour;
  droppableId: string;
  history: boolean;
}

const TourItem = ({ tour, droppableId, history }: Props) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const isLoading = useSelector(selectIsTourLoading(tour));
  const searchOrderId = parseInt(useSelector(selectSearchFieldValue));

  const deliveries = useSelector(selectDeliveriesFromTour(tour.id));

  const [notifyAllCustomers] = useNotifyAllCustomersMutation();

  const [displayModalWaypoints, setDisplayModalWaypoint] = useState(false);
  const [displayDriverSelector, setDisplayDriverSelector] = useState(false);

  const [isTourNoteModalOpen, setIsTourNoteModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for the menu
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isPresent = useMemo(
    () =>
      tour.orders.find(
        (e: Delivery) => e.order && e.order.id === searchOrderId,
      ),
    [searchOrderId],
  );

  useEffect(() => {
    if (isPresent) {
      if (ref !== null && ref.current !== null) {
        // @ts-ignore
        ref.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [isPresent]);

  const handleDateChange = (date: Date | null) => {
    if (tour && date) {
      dispatch(updateTour(tour, setMinutes(setHours(date, 8), 15)));
    }
  };

  const handleTourClick = () => {
    dispatch(setCurrentTourId(tour.id));
  };

  const deleteTourClick = () => {
    dispatch(deleteTour(tour));
  };

  const handleManualClick = () => {
    dispatch(updateTour(tour, undefined, false));
  };

  useEffect(() => {
    if (
      tour.deliveries.length > 0 &&
      (deliveries === undefined || deliveries.length === 0)
    ) {
      // Download the deliveries and partOrder for the tour
    }
  }, [deliveries]);

  let headerColor = tour.color;
  let headerTextColor = tour.textColor;
  if (tour.isOldTour && !tour.direct) {
    headerColor = '#979597';
    headerTextColor = '#FFFFFF';
  }

  return (
    <TourDiv
      direct={tour.direct}
      ref={ref}
      onClick={() => {
        dispatch(setCurrentTourId(tour.id));
      }}
      className="tour my-1 mx-3 border-2 bg-white cursor-pointer"
    >
      {tour.manual && (
        <button
          onClick={handleManualClick}
          className="absolute -ml-12 mt-8 text-white bg-orange rounded-full p-2 border"
          // data-tip={
          //   'Mode manuel, cliquer pour désactiver et calculer automatiquement le meilleur chemin'
          // }
          // data-for="manual-mode"
        >
          <IoMdHand className="h-8 w-8" />
        </button>
      )}
      <TourHeader
        className="flex items-center justify-around text-lg border border-gray-500"
        color={headerColor}
        textColor={headerTextColor}
        direct={tour.direct}
      >
        <div className="ml-0 flex items-baseline">
          {tour.isHomeTour && tour.carrier && (
            <div className="text-sm mx-1">{tour.carrier.name}</div>
          )}
          <div className="text-sm  mx-1">
            <>
              {!tour.isHomeTour && (
                <span className="uppercase">{tour.destination?.name}</span>
              )}
            </>
          </div>
          {!tour.direct && <>| {tour.id}</>}
        </div>
        {tour.isKewlox && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setDisplayDriverSelector(true);
              handleTourClick();
            }}
          >
            {tour.drivers.length > 0 ? (
              <div className="flex items-center">
                {tour.drivers.map(e => (
                  <div key={e.id} className="flex text-sm items-center mx-1">
                    <FaUserAlt className="h-4 w-4 text-white mr-1" />
                    {e.shortName}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex text-sm items-center mx-1">
                <FaUserAlt className="h-4 w-4 text-white mr-1" />
                {!tour.isOldTour && '?'}
              </div>
            )}
          </div>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          {!tour.direct && (
            <div className="date-tour ml-1">
              <ButtonDatePicker
                label={format(parseISO(tour.date), 'eee d MMM', {
                  locale: fr,
                })}
                value={parseISO(tour.date)}
                onChange={handleDateChange}
                disablePast={!tour.isOldTour && !tour.isToday}
              />
            </div>
          )}
        </MuiPickersUtilsProvider>
        {tour.isHomeTour && tour.carrier?.name === 'KEWLOX' && (
          <>
            {tour.distance && (
              <div className={`flex ml-10 items-center`}>
                <div className="text-xl">
                  {(tour.distance / 1000).toFixed(0)}{' '}
                  <span className="text-sm"> km</span>
                </div>
              </div>
            )}
            {tour.finishTime && (
              <div className={`ml-10 flex items-center hidden xxl:flex`}>
                <div className="text-base">
                  {format(parseISO(tour.finishTime), 'HH:mm')}
                </div>
              </div>
            )}
          </>
        )}
        {tour.orders && tour.orders.length > 0 && (
          <div className="hidden xl:flex ml-10 items-center">
            <div className="text-xl flex items-baseline">
              {tour.orders.length}{' '}
              <span className="text-sm ml-1">
                {tour.direct && `commande${tour.orders.length > 1 ? 's' : ''}`}

                {!tour.direct && (
                  <div className="flex flex-col">
                    <div>{`livr./${tour.workload} col.`}</div>
                    {(tour.isHomeTour || tour.destination?.id === 23) && (
                      <div className="-mt-2">
                        <span className="font-medium">
                          {Math.round(tour.totalWeight / 1000)}
                        </span>{' '}
                        kg
                      </div>
                    )}
                  </div>
                )}
              </span>
            </div>
          </div>
        )}
        {tour && tour.canManuallyUpdateDeliveriesStatus && (
          <div className="actions flex ">
            {!tour.isOldTour ? (
              <>
                {tour.isKewlox && (
                  <TourAction>
                    <button
                      onClick={() => {
                        notifyAllCustomers(tour.id).unwrap();
                      }}
                      data-tip="Avertir tous les clients par email et SMS"
                      data-for="infos"
                    >
                      <FiSmartphone className="h-5 w-5" />
                    </button>
                  </TourAction>
                )}
                {tour.isToday && (
                  <TourAction>
                    <button
                      onClick={() => {
                        dispatch(updateTour(tour, undefined, undefined, true));
                      }}
                      data-tip={`Marquer toutes les commandes comme ${
                        tour.isKewlox ? 'livrées' : 'remises au transporteur'
                      }`}
                      data-for="infos"
                    >
                      <GoPackage className="h-5 w-5 ml-3" />
                    </button>
                  </TourAction>
                )}
              </>
            ) : (
              <TourAction>
                <button
                  onClick={() => {
                    dispatch(updateTour(tour, undefined, undefined, true));
                  }}
                  data-tip={`Marquer toutes les commandes comme ${
                    tour.isKewlox ? 'livrées' : 'remises au transporteur'
                  } `}
                  data-for="infos"
                >
                  <div className="text-sm border px-2 py-1 border-white flex items-center">
                    {tour.isKewlox ? 'Livrées' : 'Expédiées'}{' '}
                    <GoPackage className="h-5 w-5 ml-1" />
                  </div>
                </button>
              </TourAction>
            )}
            <TourAction data-tip="Voir sur Google Map" data-for="infos">
              <TourMapLink tour={tour} deliveries={deliveries} />
            </TourAction>
          </div>
        )}
        {tour.shouldPrintTourList && tour.deliveries.length > 0 && (
          <TourAction>
            <TourPrinterButton tour={tour} />
          </TourAction>
        )}
        {tour.shouldPrintPackageSheetList && (
          <TourAction>
            <ShopPackagesSheets tour={tour} />
          </TourAction>
        )}
        {tour.isTourWithClientsPage && deliveries && (
          <TourAction>
            <ExternalCarrierOrderPages deliveries={deliveries} />
          </TourAction>
        )}
        {tour.orders.length === 0 && !tour.direct && (
          <TourAction>
            <button
              onClick={deleteTourClick}
              data-tip="Supprimer la tournée"
              data-for="infos"
            >
              <FiTrash2 className="h-5 w-5 ml-1" />
            </button>
          </TourAction>
        )}

        <IconButton onClick={handleMenuClick}>
          <FiMoreVertical className="h-5 w-5 text-white" />{' '}
          {/* Changed icon to white */}
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {
            // Display menu item "Modifier les livreurs"}
            tour.isKewlox && (
              <MenuItem
                onClick={() => {
                  handleTourClick();
                  setDisplayDriverSelector(true);
                  handleMenuClose(); // Close menu after action
                }}
              >
                <FaUserAlt className="mr-2" /> Modifier les livreurs
              </MenuItem>
            )
          }

          {/*          {tour.canManuallyUpdateDeliveriesStatus && tour.isKewlox && (
            <MenuItem
              onClick={() => {
                notifyAllCustomers(tour.id).unwrap();
                handleMenuClose(); // Close menu after action
              }}
            >
              <FiSmartphone className="mr-2" /> Avertir tous les clients
            </MenuItem>
          )}

          {tour.orders.length === 0 && !tour.direct && (
            <MenuItem
              onClick={() => {
                deleteTourClick();
                handleMenuClose(); // Close menu after action
              }}
            >
              <FiTrash2 className="mr-2" /> Supprimer la tournée
            </MenuItem>
          )}

          {
            // Display meny item "Voir sur Google Map"}
            tour && tour.canManuallyUpdateDeliveriesStatus && (
              <MenuItem
                onClick={() => {
                  handleMenuClose(); // Close menu after action
                }}
              >
                <span className="mr-2">
                  <TourMapLink tour={tour} deliveries={deliveries} />
                </span>{' '}
                Voir sur Google Map
              </MenuItem>
            )
          }

          {tour && tour.canManuallyUpdateDeliveriesStatus && (
            <MenuItem
              onClick={() => {
                dispatch(updateTour(tour, undefined, undefined, true));
                handleMenuClose(); // Close menu after action
              }}
            >
              <GoPackage className="mr-2" />{' '}
              {tour.isKewlox
                ? 'Marquer toutes les commandes comme livrées'
                : 'Marquer toutes les commandes comme remises au transporteur'}
            </MenuItem>
          )}



          {
            // Display menu item "Imprimer les feuilles clients"}
            tour.shouldPrintTourList && tour.deliveries.length > 0 && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                }}
              >
                <span className="mr-2">
                  <TourPrinterButton tour={tour} />
                </span>{' '}
                Imprimer les feuilles clients
              </MenuItem>
            )
          }

          {
            // Display menu item "Imprimer les feuilles de colisage"}
            tour.shouldPrintPackageSheetList && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                }}
              >
                <span className="mr-2">
                  <ShopPackagesSheets tour={tour} />
                </span>{' '}
                Imprimer les feuilles de colisage
              </MenuItem>
            )
          }

          {
            // Display menu item "Voir les feuilles de commande"
            tour.isTourWithClientsPage && (
              <MenuItem
                onClick={() => {
                  handleMenuClose(); // Close menu after action
                }}
              >
                <ExternalCarrierOrderPages deliveries={deliveries} />
              </MenuItem>
            )
          }*/}

          <MenuItem
            onClick={() => {
              setIsTourNoteModalOpen(true);
              handleMenuClose(); // Close menu after action
            }}
          >
            <FiFile className="mr-2" />{' '}
            {tour.note ? `Modifier la note` : `Ajouter une note à la tournée`}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDisplayModalWaypoint(true);
              handleMenuClose(); // Close menu after action
            }}
          >
            <FiFlag className="mr-2" /> Ajouter un point de passage
          </MenuItem>
        </Menu>
      </TourHeader>
      {isLoading ? (
        <div className="flex w-full h-20 justify-center items-center">
          <CircleSpinner size={30} color="#4a5568" />
        </div>
      ) : (
        <Droppable
          droppableId={droppableId}
          direction="horizontal"
          isDropDisabled={tour.isOldTour}
        >
          {(provided, snapshot) => (
            <TourContentStyled
              ref={provided.innerRef}
              className="flex items-center overflow-auto"
              isDraggingOver={snapshot.isDraggingOver}
              manual={tour.manual}
            >
              {deliveries &&
                deliveries
                  .filter(delivery => delivery)
                  ?.sort((a, b) => a!.position - b!.position)
                  .map(delivery => (
                    <DeliveryItem
                      key={delivery!.id}
                      position={delivery!.position}
                      tourDate={tour.date}
                      deliveryId={delivery!.id}
                      history={history}
                      tourId={tour.id}
                    />
                  ))}
            </TourContentStyled>
          )}
        </Droppable>
      )}
      {displayDriverSelector && (
        <DriverSelector
          tour={tour}
          displayDriverSelector={displayDriverSelector}
          setDisplayDriverSelector={setDisplayDriverSelector}
        />
      )}

      {displayModalWaypoints && (
        <WaypointModal show={setDisplayModalWaypoint} tour={tour} />
      )}

      <TourNote
        note={tour.note}
        tourId={tour.id}
        isTourNoteModalOpen={isTourNoteModalOpen}
        setIsTourNoteModalOpen={setIsTourNoteModalOpen}
      />
    </TourDiv>
  );
};

export default TourItem;

const TourDiv = styled.div<{
  direct: boolean;
}>`
  ${p =>
    p.direct &&
    css`
      position: sticky;
      top: 67px;
      z-index: 1000;
    `}
`;

const TourHeader = styled.div<{
  color: string | undefined;
  textColor: string | undefined;
  direct: boolean;
}>`
  ${p =>
    p.color &&
    css`
      background-color: ${p.color};
    `}

  ${p =>
    p.textColor &&
    css`
      color: ${p.textColor};
    `}
    ${p =>
    !p.direct &&
    css`
      height: 36px;
    `}
`;

const TourContentStyled = styled.div<{
  isDraggingOver: boolean;
  manual?: boolean;
}>`
  padding: 0.25rem 0;
  min-height: 3rem;

  ${p =>
    p.isDraggingOver &&
    css`
      background-color: rgba(212, 211, 211, 0.64);
    `}
`;

export const TourAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0.25rem;
`;
