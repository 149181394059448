/* eslint-disable prefer-promise-reject-errors,@typescript-eslint/camelcase */
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */

function checkAuth(options) {
  const token = localStorage.getItem('token');
  if (token !== null && token !== undefined) {
    options.headers = { ...options.headers, Authorization: `JWT ${token}` };
  }
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Parses the HTML returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed HTML from the request
 */
function parseHTML(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return response.json().then(json =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      body: json,
    }),
  );
}

const handleError = error => {
  const errorMessage =
    'Cannot connect. Please make sure you are connected to internet.';
  error = {
    status: 0,
    ok: false,
    statusText: errorMessage,
    body: { non_field_errors: errorMessage },
  };
  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('Error return :', error);
      throw error;
    });
}

export function requestHTML(url, options) {
  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseHTML)
    .catch(error => {
      console.log('error return :', error);
      throw error;
    });
}

export function requestPost(url, data, token = null) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  checkAuth(options);

  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('error return :', error);
      throw error;
    });
}

export function requestPostJson(url, data, token = null) {
  const options = {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  checkAuth(options);

  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('error return :', error);
      throw error;
    });
}

export function requestPut(url, data, token = null) {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  checkAuth(options);

  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('error return :', error);
      throw error;
    });
}

/**
 * GET Request HTTP
 *
 * @param  {string} url       The URL we want to request
 * @param  {string} token     The user token in order to authentificate
 * @param  {array} params    The GET parametters for request
 *
 * @return {object}           The response data
 */
export function requestGet(url, token = null, params = null) {
  let requestURL = url;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  checkAuth(options);

  if (params && Object.keys(params).length > 0) {
    const getParams = [];
    Object.keys(params).forEach(key => {
      getParams.push(`${key}=${params[key]}`);
    });
    requestURL = `${requestURL}?${getParams.join('&')}`;
  }

  return fetch(requestURL, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('error return :', error);

      if (error.status === 401) {
        if (localStorage.getItem('token')) localStorage.removeItem('token');
      }

      throw error;
    });
}

export function requestDelete(url, token = null) {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  checkAuth(options);

  return fetch(url, options)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      console.log('error return :', error);
      throw error;
    });
}
