import React from 'react';
import TimelineItem from './TimelineItem';
import './timeline-style.css';
import { Event } from 'app/models/Feed';

interface Props {
  events: Event[];
  limit?: number;
}

const Timeline = ({ events, limit }: Props) => {
  if (events.length > 0) {
    return (
      <div className="timeline-container">
        {events.map(event => (
          <TimelineItem event={event} key={event.eventId} />
        ))}
      </div>
    );
  }
  return null;
};

export default Timeline;
